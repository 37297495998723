import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './uninsured.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

const Datas = [
  {
    sort: '검사료',
    contents: [
      {
        content: '액상세포검사(T/P)',
        price: 40000,
      },
      {
        content: '일반세포검사(pap)',
        price: 20000,
      },
      {
        content: '인유두종바이러스(HPV)',
        price: 60000,
      },
      {
        content: '자궁경부확대경검사',
        price: 30000,
      },
      {
        content: '소변임신검사',
        price: 10000,
      },
      {
        content: '혈액임신검사',
        price: 30000,
      },
      {
        content: 'STD검사',
        price: 120000,
      },
      {
        content: '풍진항원항체검사',
        price: 50000,
      },
      {
        content: '난소기능검사(AMH)',
        price: 75000,
      },
    ],
  },
  {
    sort: '예방접종',
    contents: [
      {
        content: 'A형 간염',
        price: 70000,
      },
      {
        content: 'B형 간염',
        price: 35000,
      },
      {
        content: '독감(수입)',
        price: 35000,
      },
      {
        content: '가다실 4가',
        price: 150000,
      },
      {
        content: '가다실 9가',
        price: 210000,
      },
      {
        content: '백일해(파상풍)',
        price: 50000,
      },
      {
        content: '풍진',
        price: 30000,
      },
    ],
  },

  {
    sort: '검진',
    contents: [
      {
        content: '웨딩 검진',
        price: 280000,
      },
      {
        content: '중년 검진',
        price: 300000,
      },
      // {
      //   content: '경부암 정밀검사 (공단)',
      //   price: 80000,
      // },
      // {
      //   content: '경부암 정밀검사 (일반)',
      //   price: 100000,
      // },
    ],
  },

  {
    sort: '처치, 시술',
    contents: [
      {
        content: '일반루프',
        price: 100000,
      },
      {
        content: '미레나',
        price: 300000,
      },
      {
        content: '카일리나',
        price: 330000,
      },
      {
        content: '임플라논',
        price: 380000,
      },
      {
        content: '유착방지제',
        price: 100000,
      },

      {
        content: '헤모블럭',
        price: 50000,
      },
      {
        content: '질스케일링',
        price: 5000,
      },
    ],
  },

  {
    sort: '주사료',
    contents: [
      {
        content: '영양제',
        minPrice: '50,000~',
      },
      {
        content: '프로게스트테론주사',
        // price: 20000,
        minPrice: '15,000~',
        maxPrice: '35,000',
      },
      // {
      //   content: '삭센다',
      //   price: 120000,
      // },
      {
        content: '입덧 주사',
        price: 40000,
      },
      {
        content: '몸살 감기',
        price: 40000,
      },
      {
        content: 'vit D 주사 1회',
        price: 30000,
      },
      {
        content: 'vit D 주사 4회',
        price: 80000,
      },
      {
        content: '태반 주사 1회',
        price: 30000,
      },
      {
        content: '태반 주사 5회',
        price: 130000,
      },
      {
        content: '만성 피로 1회',
        price: 50000,
      },
      {
        content: '만성 피로 5회',
        price: 220000,
      },
      {
        content: '백옥 주사 1회',
        price: 20000,
      },
      {
        content: '백옥 주사 5회',
        price: 90000,
      },
      // {
      //   content: '신데렐라 1회',
      //   price: 40000,
      // },
      // {
      //   content: '신데렐라 5회',
      //   price: 180000,
      // },
    ],
  },
  {
    sort: '초음파',
    contents: [
      {
        content: '산모초음파',
        price: 30000,
      },
      {
        content: '부인과초음파',
        price: 50000,
      },
      {
        content: '입체초음파',
        price: 50000,
      },
    ],
  },

  {
    sort: '제증명서',
    contents: [
      {
        content: '일반진단서',
        price: 20000,
      },
      {
        content: '영문진단서',
        price: 20000,
      },
      // {
      //   content: '수술확인서',
      //   price: 5000,
      // },
      {
        content: '진료확인서/통원확인서',
        price: 3000,
      },
    ],
  },
];

function Uninsured(): ReactElement {
  const menuIndex = 0;
  const subIndex = 1;

  return (
    <>
      <Header />
      <Styled.Container>
        <Styled.TitleText>
          아라산부인과의원 <mark>비급여항목 안내</mark>입니다.
        </Styled.TitleText>

        <Common.SizedBoxH height={24} />

        <Typo.Pretendard_Regular height={92} textAlign="center" fontSize={20} lineHeight={30} color={Colors.black}>
          본 페이지는 의료법 제45조 제1항 및 제2항과 시행규칙 제42조의 2 제1항 및 제2항에 의하여 비급여 진료비용을 고지하기 위한 화면입니다.
          <br />
          비급여 진료비용은 단일 개별 항목의 1회 비용이므로 진료과정에서 처방량에 따라 해당 항목의 비용이 달라질 수 있습니다.
        </Typo.Pretendard_Regular>

        <Common.SizedBoxH height={22} />

        <Common.SizedBox width={1160} height={4}>
          <Common.Fill color="#D9D9D9" />
        </Common.SizedBox>

        <Styled.Table>
          <tr>
            <th style={{ width: '155px' }} rowSpan={2}>
              중분류
            </th>
            <th style={{ width: '154px', height: '40px' }} rowSpan={2}>
              소분류
            </th>
            <th style={{ width: '155px' }} colSpan={2}>
              항목
            </th>
            <th style={{ width: '388px' }} colSpan={5}>
              진료비용
            </th>
            <th style={{ width: '154px' }} rowSpan={2}>
              특이사항
            </th>
            <th style={{ width: '155px' }} rowSpan={2}>
              최종 변경일
            </th>
          </tr>
          <tr>
            <th style={{ width: '77px', height: '60px' }}>코드</th>
            <th style={{ width: '77px', height: '60px' }}>명칭</th>
            <th style={{ width: '77px', height: '60px' }}>비용</th>
            <th style={{ width: '77px', height: '60px' }}>최저비용</th>
            <th style={{ width: '77px', height: '60px' }}>최고비용</th>
            <th style={{ width: '77px', height: '60px' }}>치료재료대 포함여부</th>
            <th>약제비 포함여부</th>
          </tr>

          {Datas.map((e) =>
            e.contents.map((row, i) => (
              <tr>
                {i == 0 && <td rowSpan={e.contents.length}>{e.sort}</td>}
                <td>{row.content}</td>
                <td></td>
                <td></td>
                <td>{row.price?.toLocaleString()}</td>
                <td>{row.minPrice?.toLocaleString()}</td>
                <td>{row.maxPrice?.toLocaleString()}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>2023-07-01</td>
              </tr>
            )),
          )}
        </Styled.Table>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default Uninsured;
